;(function () {
	'use strict';
	
	// init main navigation
	ready(function() {
		var menu = document.querySelector('.js-accountmenu');
		if (typeof(menu) != 'undefined' && menu != null) {
			var nav = new AccountNavigation(menu);
		}
	});

	// Constructor
	function AccountNavigation (nav) {
		
		var self = this;

		// Save a reference to the element
		this.menu = nav.querySelector('.js-accountmenu-items');
		if (typeof(this.menu) != 'undefined' && this.menu != null) {
			this.menuId = this.menu.id;
			
			// Get the button
			this.button = nav.querySelector('.js-accountmenu-toggle');
			// If the menu button doesn't exist exit with an error
			if (!this.button) {
				throw new Error('Button `.js-accountmenu-toggle` not found.');
			}
	
			// Add (initial) button semantics
			this.button.setAttribute('aria-haspopup', true);
			this.button.setAttribute('aria-expanded', false);
			this.button.setAttribute('role', 'button');
			this.button.setAttribute('aria-controls', this.menuId);
	
			// Handle button click
			this.button.addEventListener('click', function (e) {
				e.preventDefault();
				this.toggle();
			}.bind(this))
	
			// Also toggle on key interactions
			this.button.addEventListener('keydown', function (e) {
				
				if (e.keyCode === 13 || e.keyCode === 32) { 
					// enter or space
					e.preventDefault();
					this.toggle();
					
				} else if (e.keyCode === 40) { 
					// down arrow
					if (this.button.getAttribute('aria-expanded') !== 'true') {
						e.preventDefault();
						this.open();
					} else {
						this.menu.querySelector('a').focus()
					}
					
				} else if ((e.shiftKey && e.keyCode === 9)) { 
					// shift+tab
					this.close(false);
					
				} else if (e.keyCode === 9) { 
					// or tab
					if (this.button.getAttribute('aria-expanded') === 'true') {
						e.preventDefault();
						this.menu.querySelector('a').focus()
					}
					
				} else if (e.keyCode === 38) { 
					// up arrow
					this.close();
					
				} else if (e.keyCode === 27) { 
					// escape
					this.close();
				}
			}.bind(this))
	
			// Get the all top level links within the menu
			this.menuLinks = this.menu.querySelectorAll('a');
			if (this.menuLinks.length < 1) {
				throw new Error('The #' + this.menuId + ' menu has no menu items');
			}
	
			// Handle key presses for menuItem
			Array.prototype.forEach.call(this.menuLinks, function (link, index) {
				
				// add keyboard event
				link.addEventListener('keydown', function (e) {
					if (e.shiftKey && e.keyCode === 9) { 
						// shift+tab
						focusNext(e, false);
					
					} else if (e.keyCode === 38) { 
						// up arrow
						focusNext(e, false);
					
					} else if (e.keyCode === 40 || e.keyCode === 9) { 
						// down arrow or tab
						focusNext(e, true);
	
					} else if (e.keyCode === 27) { 
						// escape
						this.close();
					}
				}.bind(this))
			}.bind(this))
			
			var focusNext = function (event, down) {
				var link = event.target;
				var goingDown = down;
				
				// helper function for getting next legitimate element
				function getNextElement(link) {
					if (goingDown) {
						
						if ( // check if there is a visible next nav element
							typeof link.parentNode.nextElementSibling !== "undefined" 
							&& link.parentNode.nextElementSibling != null
							&& !isElementHidden(link.parentNode.nextElementSibling)
						) { 
							console.log('down');
							return link.parentNode.nextElementSibling.querySelector('a');
							
						} else if ( // check if close button is available
							typeof link.parentNode.parentNode.previousElementSibling !== "undefined" 
							&& link.parentNode.parentNode.previousElementSibling != null
							&& link.parentNode.parentNode.previousElementSibling.tagName == 'BUTTON'
							&& !isElementHidden(link.parentNode.parentNode.previousElementSibling)
						) {
							console.log('button');
							return link.parentNode.parentNode.previousElementSibling;
							
						} else {
							console.log('no move');
							return link;
						}
						
					} else {
						
						if ( // check if there is a visible next nav element
							typeof link.parentNode.previousElementSibling !== "undefined" 
							&& link.parentNode.previousElementSibling != null
							&& !isElementHidden(link.parentNode.previousElementSibling)
						) { 
							console.log('up');
							return link.parentNode.previousElementSibling.querySelector('a');
							
						} else if ( // check if close button is available
							typeof link.parentNode.parentNode.previousElementSibling !== "undefined" 
							&& link.parentNode.parentNode.previousElementSibling != null
							&& link.parentNode.parentNode.previousElementSibling.tagName == 'BUTTON'
							&& !isElementHidden(link.parentNode.parentNode.previousElementSibling)
						) {
							console.log('button');
							return link.parentNode.parentNode.previousElementSibling;
							
						} else {
							console.log('no move');
							return link;
						}
					}
				}
				
				function isElementHidden(element) {
				    return (element.offsetParent === null);
				}
				
				// don't allow default movement
				event.preventDefault();
	
				// make move
				var nextItem = getNextElement(link);
				if (nextItem !== link) {
					nextItem.focus();
				}
	
			}.bind(this)
	
			// register outside click
			this.outsideClick = function (e) {
				if (this.button.getAttribute('aria-expanded') === 'true' && !this.menu.contains(e.target) && !this.button.contains(e.target)) {
					this.close(false);
				}
			}
			document.addEventListener('click', this.outsideClick.bind(this));
	
			// initiate listeners object for public events
			this._listeners = {}
		}
	}
	
	// Open methods
	AccountNavigation.prototype.open = function () {
		// open nav
		this.button.setAttribute('aria-expanded', true);
		// fire open event
		this._fire('open');
		return this;
	}

	// Close methods
	AccountNavigation.prototype.close = function (setFocus) {
		setFocus = typeof setFocus === "undefined" ? true : setFocus;
		// close nav
		this.button.setAttribute('aria-expanded', false);
		// set focus
		if (setFocus) {
			this.button.focus();
		}
		// fire close event
		this._fire('close');
		return this;
	}

	// Toggle methods
	AccountNavigation.prototype.toggle = function () {
		var expanded = this.button.getAttribute('aria-expanded') === 'true';
		return expanded ? this.close() : this.open();
	}
	
	// focus menu button
	AccountNavigation.prototype.focusMenuButton = function () {
		this.button.focus();
		return this;
	}

	AccountNavigation.prototype._fire = function (type, data) {
		if (typeof this._listeners === 'undefined') {
			this._listeners = [];
		}
		var listeners = this._listeners[type] || [];

		listeners.forEach(function (listener) {
			listener(data);
		})
	}

	AccountNavigation.prototype.on = function (type, handler) {
		if (typeof this._listeners[type] === 'undefined') {
			this._listeners[type] = [];
		}

		this._listeners[type].push(handler);

		return this;
	}

	AccountNavigation.prototype.off = function (type, handler) {
		var index = this._listeners[type].indexOf(handler);

		if (index > -1) {
			this._listeners[type].splice(index, 1);
		}

		return this;
	}

	// Export AccountNavigation
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = AccountNavigation;
	} else if (typeof define === 'function' && define.amd) {
		define('AccountNavigation', [], function () {
			return AccountNavigation;
		})
	} else {
		// attach to window
		window.AccountNavigation = AccountNavigation;
	}
}());
